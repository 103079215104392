.customers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.customers-section {
    max-width: 915px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.customer-search-container {
    display: flex;
    align-items: center;
    gap: 10px
}

@media (max-width: 500px) {
    .customer-search-container {
        flex-direction: column;
    }
}