.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #edf6f9;
}

.home-logo-container {
    width: 230px;
    margin-bottom: 40px;
}
.home-logo-image {
    width: 100%;
}

.home-login-section {
    width: 350px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #83c5be;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}