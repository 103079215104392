.history-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.history-table-container {
    max-width: 1000px;
    margin: 20px 0;
}