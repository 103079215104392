.booking-add-edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.booking-add-edit-available-skips-container {
    width: 440px;
    background-color: #83c5be;
    color: #006d77;
    padding: 15px;
    display: flex;
    align-items: center;
}

.booking-add-edit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.booking-add-edit-form-buttons {
    display: flex;
    flex-direction: row;
}

.booking-add-edit-switches {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 150px;
    margin: 16px 0 5px 0;
}

@media (max-width: 500px) {
    .booking-add-edit-form {
        margin-top: 20px;
    }

    .booking-add-edit-available-skips-container {
        width: 250px;
        flex-direction: column;
    }

    .booking-add-edit-form-buttons {
        flex-direction: column;
    }
}