.customer-bookings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.customer-bookings-section {
    max-width: 915px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.customer-bookings-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 16px;
  }