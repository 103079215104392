.bookings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.bookings-section {
    max-width: 915px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}