.customer-add-edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.customer-add-edit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.customer-add-edit-form-buttons {
    display: flex;
    flex-direction: row;
}

@media (max-width: 500px) {
    .customer-add-edit-form-buttons {
        flex-direction: column;
    }
}